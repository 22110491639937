import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import Header from './shared/components/Header/Header';
import Footer from './shared/components/Footer/Footer';
import Home from './Home/page/Home';
import PrivacyPolicy from './PrivacyPolicy/page/PrivacyPolicy';
import Refund from './RefundPolicy/page/RefundPolicy';
import CancelService from './CancelService/page/CancelService';
import Contact from './Contact/page/Contact';
import Price from './Price/page/Price';
import AboutUs from './AboutUs/page/AboutUs';
import Checkout from './Checkout/page/Checkout';
import TermsOfService from './TermsOfService/page/TermsOfService';
import haveAccess from './utils/helpers/validators/routeValidation';
import NotFound from './NotFound/page/NotFound';
import SecurityPolicy from './SecurityPolicy/page/securityPolicy.jsx';
import DeliveryPolicy from './DeliveryPolicy/deliveryPolicy.jsx';
import FloatingCancelButton from './shared/components/FloatingCancelButton/FloatingCancelButton';

function App() {
   useEffect(() => {

    
     const Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
       (function(){
       const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
       s1.async=true;
       s1.src=process.env.REACT_APP_TAWK_PROPERTY;
       s1.charset='UTF-8';
       s1.setAttribute('crossorigin','*');
       s0.parentNode.insertBefore(s1,s0);
       })();
   }, []);
  
  return (
    <Router>
      <Header />
      <FloatingCancelButton />
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/home" exact>
            <Home />
          </Route>

          <Route path="/privacy-policy" exact>
            <PrivacyPolicy />
          </Route>

          <Route path="/refund-policy" exact>
            <Refund />
          </Route>

          <Route path="/cancel-service" exact>
            <CancelService />
          </Route>

          <Route path="/contact" exact>
            <Contact />
          </Route>

          <Route path="/price" exact>
            <Price />
          </Route>

          <Route path="/about-us" exact>
            <AboutUs />
          </Route>


          <Route path="/checkout"
            render={() =>
              haveAccess() ? (
                <Checkout />
              ) : (
                <Redirect to="/" />
              )
            }
            exact>

          </Route>


          <Route path="/terms-of-service" exact>
            <TermsOfService />
          </Route>

          <Route path="/security-policy" exact>
            <SecurityPolicy />
          </Route>

          <Route path="/delivery-policy" exact>
            <DeliveryPolicy/>
          </Route>

          <Route path="**">
            <NotFound />
          </Route>



        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
